import React from 'react';
import { Skeleton } from '@material-ui/lab';
import SkeletonFooter from 'components/footer/skeleton';

import useStyles from 'apputil/view-styles';

import { Paper } from '@material-ui/core';

const SkeletonCreateInformationView = () => {
  const styles = useStyles();

  return (
      <>
      <Skeleton
        animation="wave"
        component="div"
        height={300}
        variant="rect"
        />
      <Skeleton animation="wave" height={50} className={styles.bodyHeader}/>
      <Skeleton animation="wave" height={25} className={styles.body2Indent}/>
        <Paper className={styles.paper} elevation={4}>
          <Skeleton animation="wave" height={150} />
          <Skeleton animation="wave" height={70} />
          <Skeleton animation="wave" height={70} />
          <Skeleton animation="wave" height={70} />
          <Skeleton animation="wave" height={70} />
          <Skeleton animation="wave" height={70} />
          <Skeleton animation="wave" height={70} />
          <Skeleton animation="wave" height={70} />
          <Skeleton animation="wave" height={50} />
          <Skeleton animation="wave" height={150} />
        </Paper>
        <SkeletonFooter/>
      </>
  );
};

export default SkeletonCreateInformationView;
