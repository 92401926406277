import React from 'react';
import { string } from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import loadable from '@loadable/component';

import { CARD_ROUTE, UPDATE_ROUTE, UPDATE_MEDIA_ROUTE } from 'constants/navigation';
import { MANAGE_CARD_NAV, UPDATE_NAV, UPDATE_MEDIA_NAV } from 'constants/font-awesome';

import SkeletonView from 'views/loyalty-card/create/skeleton';
import useStyles from './styles';

const LazyBanner = loadable(() => import('components/banners/in-app-banners/one'));
const Page = loadable(() => import('components/page'));
const ApplicationFooter = loadable(() => import('components/footer/application-footer'));
const UpdateMediaView = loadable(() => import('views/loyalty-card/update-media'));

const Typography = loadable(() => import('@material-ui/core/Typography'));

const SuspendedUpdateLoyaltyCardPage = ({ passId = '' }) => {
  const {
    cmsJson: {
      pages: {
        updateMedia,
      },
    },
  } = useStaticQuery(
    graphql`
      query  {
      cmsJson {
        pages {
          updateMedia {
            title
            message
          }
        }
      }
    }`,
  );

  const styles = useStyles();

  const { title, message } = updateMedia;

  return (
   <Page
    title="Update media"
    description={message}>
     <LazyBanner/>
     <Typography variant="h2" component="h1" className={styles.heading} align="center">
     {title}
     </Typography>
     <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
      {message}
     </Typography>
     <UpdateMediaView passId={passId} fallback={<SkeletonView />}/>
     <ApplicationFooter
       navItems={[
         { route: `${CARD_ROUTE}?passId=${passId}`, icon: MANAGE_CARD_NAV, message: 'Card' },
         { route: `${UPDATE_ROUTE}?passId=${passId}`, icon: UPDATE_NAV, message: 'Update' },
         { route: `${UPDATE_MEDIA_ROUTE}?passId=${passId}`, icon: UPDATE_MEDIA_NAV, message: 'Media' },
       ]}
       currentItem="Media"
       />
   </Page>
  );
};

SuspendedUpdateLoyaltyCardPage.propTypes = { passId: string, title: string };

export default SuspendedUpdateLoyaltyCardPage;
